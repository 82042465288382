import React, { useState } from "react"
import NoSidebarLayout from "../layout/NoSidebarLayout"
import styled from "styled-components"
import { RealButton } from "../components/ButtonElement"
import { contactBanner } from "../images"
import { graphql } from "gatsby"

const sidebar = [
  {
    to: "aboutUs",
    title: "About Us",
  },
  {
    to: "ourStory",
    title: "Our Story",
  },
  {
    to: "ourTeam",
    title: "Our Team",
  },
  {
    to: "ourAchievements",
    title: "Our Achievements",
  },
]

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const FormSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 10px 30px 10px;
`

export default function HomestayApplication() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    success: false,
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "homestayApplication", ...formState }),
    })
      .then(() => {
        setFormState({
          name: "",
          email: "",
          number: "",
          message: "",
          address1: "",
          address2: "",
          city: "",
          county: "",
          postcode: "",
          success: true,
        })
      })
      .catch(error => alert(error))
    e.preventDefault()
  }

  return (
      <div>
        <NoSidebarLayout
          sidebar={sidebar}
          pageTitle="Homestay Enquiry"
          background={contactBanner}
          pageDescription="To begin your hosting journey, please let us know below"
        >
          {formState.success && (
            <div class="alert alert-success" role="alert">
              Your message has been submitted
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            name="homestayApplication"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="homestayApplication" />
            <FormContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Your Full Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="John Smith"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput2">Email address</label>
                  <input
                    required
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput2"
                    placeholder="name@example.com"
                    name="email"
                    onChange={handleChange}
                    value={formState.email}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput3">Phone number</label>
                  <input
                    required
                    type="tel"
                    class="form-control"
                    id="exampleFormControlInput3"
                    placeholder="e.g. +44 (0)1274 679710"
                    name="number"
                    onChange={handleChange}
                    value={formState.number}
                  />
                </div>
                <div class="form-group">
                  <label for="inputAddress">Address</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    placeholder="1234 Main St"
                    name="address1"
                    onChange={handleChange}
                    value={formState.address1}
                  />
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress2"
                    placeholder="Apartment, studio, or floor"
                    name="address2"
                    onChange={handleChange}
                    value={formState.address2}
                  />
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputCity">City</label>
                    <input required type="text" class="form-control" id="inputCity" name="city"
                    onChange={handleChange}
                    value={formState.city}/>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputState">County</label>
                    <input required type="text" class="form-control" id="inputCounty" name="county"
                    onChange={handleChange}
                    value={formState.county}/>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="inputZip">Postcode</label>
                    <input required type="text" class="form-control" id="inputZip" name="postcode"
                    onChange={handleChange}
                    value={formState.postcode}/>
                  </div>
                </div>
              </FormSubContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">
                    Preferred contact method/time
                  </label>
                  <textarea
                    required
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="10"
                    name="message"
                    onChange={handleChange}
                    value={formState.message}
                  ></textarea>
                </div>
                <RealButton type="submit">Submit</RealButton>
              </FormSubContainer>
            </FormContainer>
          </form>
        </NoSidebarLayout>
      </div>
  )
}
